import React, {useEffect, useState} from 'react';
import s from './ConfigLink.module.scss'
import axios from "axios";
import {weatherHeaders} from "../../utils/consts/common";
import {cls} from "../../utils/helpers/classNames";

const ConfigLink = () => {
    const queryPublicId = new URLSearchParams(window.location.search).get('publicId')
    const queryStopId = new URLSearchParams(window.location.search).get('stopId')
    const [publicId, setPublicId] = useState(queryPublicId || '')
    const [stopId, setStopId] = useState(queryStopId || '')
    const [stopName, setStopName] = useState(queryStopId || '')
    const [isMakeRequests, setIsMakeRequests] = useState(true)
    const [cityWeatherName, setCityWeatherName] = useState('')
    const [cityWeatherId, setCityWeatherId] = useState('')
    const [stops, setStops] = useState([])
    const [filteredStops, setFilteredStops] = useState([])
    const [weatherCities, setWeatherCities] = useState([])
    const [buttonMessage, setCopySuccess] = useState('Copy link');
    const [sBoadrdId, setSBoardId] = useState('')

    useEffect(() => {
        const divElements = document.getElementsByClassName('weather-widget');
        if (divElements?.[0]) {
            divElements[0].style.display = 'none';
        }
    }, []);

    useEffect(() => {
        if (publicId) {
            fetchStops()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicId])


    const onChangeWeatherId = (e) => {
        if (!e.target.value.length) {
            setCityWeatherId('')
        }
        setCityWeatherName(e.target.value)
        setIsMakeRequests(true)
        fetchCity(e.target.value)
    }

    async function fetchCity(cityName) {
        if (!isMakeRequests) {
            return null;
        }
        const requestData = {
            list: 1,
            str: cityName,
            lang: 'uk'
        }
        const response = await axios.post('https://app3.weatherwidget.org/data/', requestData, {
            headers: weatherHeaders
        })

        if (Array.isArray(response.data) && cityName) {
            setWeatherCities(response.data)
        } else {
            setWeatherCities([])
        }
    }

    async function fetchStops() {
        const domain =
            window.location.host === 'https://sboard.dev.intranso.com' || window.location.host === 'localhost:3001'
                ? 'https://ui.dev.intranso.com'
                : 'https://pcounter.gemicle.com/'

        try {
            const response = await axios.get(
                `${domain}/ui/api/dispatching/public/locator/stop/all?publicId=${publicId}`, {
                    withCredentials: true
                }
            )

            setStops(response.data)
            setFilteredStops(response.data)
        } catch (e) {
            console.log('no stops')
        }
    }

    const onClickCity = (city) => () => {
        setIsMakeRequests(false)
        setCityWeatherName(city.name.split(',')[0])
        setCityWeatherId(city.id)
        setWeatherCities([])
    }

    const onClickStop = (stop) => () => {
        setStopName(stop.name)
        setStopId(stop.id)
        setFilteredStops([])
    }

    const isDisabled = !publicId || !stopId || !cityWeatherId || !sBoadrdId
    const resultLink = `${window.location.origin}/sboard/${publicId}/${stopId}/${cityWeatherId}/${sBoadrdId}`


    const onCopy = () => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(resultLink).then(
                () => {
                    setCopySuccess('Copied!');
                    setTimeout(() => setCopySuccess('Copy link'), 2000);
                },
                err => {
                    console.error('Failed to copy: ', err);
                }
            );
        }
    };

    const onChangeStopName = (e) => {
        if (stops.length) {
            setStopName(e.target.value)
            setFilteredStops(stops.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())))
        } else {
            setFilteredStops(stops)
        }
    }
    const onChangePublicId = (e) => {
        setPublicId(e.target.value)
        setStopName('')
        setStopId('')
        setFilteredStops([])
        setStops([])
    }

    return (
        <div className={s.formWrapper}>
            <h3>SBoard Configurator:</h3>
            <div className={s.form}>
                <label>
                    <p>Public id:<span>*</span></p>
                    <input value={publicId} onChange={onChangePublicId} />
                </label>
                <label>
                    <p>Stop:<span>*</span></p>
                    <input
                        value={stopName}
                        onChange={onChangeStopName}
                        className={cls('', {[s.inputDisabled]: !stops.length})}
                    />
                    <div className={cls(s.dropdown, {[s.visibleList]: filteredStops.length})}>
                        <div>
                            {filteredStops?.map(stop => (
                                <div className={s.listItem} onClick={onClickStop(stop)}>
                                    <span>{stop.name} </span>
                                    {stop.description ? <span>({stop.description})</span> : <span>(...{stop.id.slice(-6)})</span> }
                                </div>
                            ))}
                        </div>
                    </div>
                </label>
                <label>
                    <p>Weather city (eng):<span>*</span></p>
                    <input value={cityWeatherName} onChange={onChangeWeatherId} style={{width: 350}} />
                    <div className={cls(s.dropdown, {[s.visibleList]: weatherCities.length})}>
                        <div>
                            {weatherCities?.map(city => (
                                <div className={s.listItem} onClick={onClickCity(city)}>{city.name}</div>
                            ))}
                        </div>
                    </div>
                </label>
                <label>
                    <p>SBoard id:<span>*</span></p>
                    <input value={sBoadrdId} onChange={(e) => setSBoardId(e.target.value)} />
                </label>
                <div className={s.linkResult}>
                    <span>Link: </span>
                    <a target={"_blank"} rel="noreferrer" href={resultLink}><span>{resultLink}</span></a>
                </div>
                <button
                    disabled={isDisabled}
                    className={cls('', {[s.btnDisabled]: isDisabled})}
                    onClick={onCopy}
                >
                    {buttonMessage}
                </button>
            </div>
        </div>
    );
};

export default ConfigLink;
