// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarqueeRow_hidden__PTBMs {
  display: none;
}

.MarqueeRow_alertMessage__ZmORz {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font: var(--font-32);
  gap: 16px;
  padding-right: 16px;
  background-color: #FF735C;
  overflow: hidden;
  height: 65px;
  color: #000;
}

.MarqueeRow_shelterInfo__ePFJ8 {
  background: #3AB163;
  color: #000;
  height: 65px;
  overflow: hidden;
  width: 100%;
  font: var(--font-32);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 32px;
  box-sizing: border-box;
}

.MarqueeRow_shelterMessage__smFsu {
  animation: MarqueeRow_fadeInOut__LVP9A 7s ease-in-out infinite;
  opacity: 0;
}

@keyframes MarqueeRow_fadeInOut__LVP9A {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Marquee/MarqueeRow.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,sBAAA;EACA,sBAAA;AACF;;AAEA;EACE,8DAAA;EACA,UAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".hidden {\n  display: none;\n}\n\n.alertMessage {\n  display: flex;\n  align-items: center;\n  text-transform: uppercase;\n  font: var(--font-32);\n  gap: 16px;\n  padding-right: 16px;\n  background-color: #FF735C;\n  overflow: hidden;\n  height: 65px;\n  color: #000;\n}\n\n.shelterInfo {\n  background: #3AB163;\n  color: #000;\n  height: 65px;\n  overflow: hidden;\n  width: 100%;\n  font: var(--font-32);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px 0 32px;\n  box-sizing: border-box;\n}\n\n.shelterMessage {\n  animation: fadeInOut 7s ease-in-out infinite;\n  opacity: 0;\n}\n\n@keyframes fadeInOut {\n  0% {\n    opacity: 0;\n  }\n  30% {\n    opacity: 1;\n  }\n  70% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `MarqueeRow_hidden__PTBMs`,
	"alertMessage": `MarqueeRow_alertMessage__ZmORz`,
	"shelterInfo": `MarqueeRow_shelterInfo__ePFJ8`,
	"shelterMessage": `MarqueeRow_shelterMessage__smFsu`,
	"fadeInOut": `MarqueeRow_fadeInOut__LVP9A`
};
export default ___CSS_LOADER_EXPORT___;
